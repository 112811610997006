import dayjs from 'dayjs';
import SyncIcon from '@mui/icons-material/SyncRounded';
import StatusIcon from '@mui/icons-material/SyncProblemRounded';
import StopIcon from '@mui/icons-material/StopCircleOutlined';
import React from 'react';
import MoreIconList from '../../../components/ellipsis-menu/MoreIconList';
import { statusColor } from "../../../styles/statusColors";
import { requestMeterValues, requestRemoteStop, requestStatusNotification } from '../../../utils/ocppCommands';

const getMoreIcon = (session: any) => {
  const activeSessionsMenu = [
    {
      icon: () => <SyncIcon color="info" />,
      title: 'Update Meter Values',
      clickHandler: () => requestMeterValues({connectorId: session.connectorNumber, chargePointId: session.evseId }),
      confirmText: 'Refresh meter values?'
    },
    {
      icon: () => <StatusIcon color="success" />,
      title: 'Check Status',
      clickHandler: () => requestStatusNotification({connectorId: session.connectorNumber, chargePointId: session.evseId }),
      confirmText: 'Request status notification?'
    },
    {
      icon: () => <StopIcon color="error" />,
      title: 'Stop Session',
      clickHandler: () => requestRemoteStop(session.evseId, session.transactionId),
      confirmText: 'Stop this charging session?'
    },
  ]

  return <MoreIconList menuItems = { activeSessionsMenu }/>
};

export const formatActiveSessionTableData = (chargingSessions: any, evses: any, connectors: any) => {
  const activeSessions = chargingSessions
  return activeSessions.map((session: any) => {
    const connector = connectors.find((connector: any) => session.connectorId === connector.id);
    const evse = evses.find((evse: any) => session.evseId === evse.id);
    return  {
      'Transaction Id': session.transactionId,
      'EVSE': evse ? evse.vendor : ' - ',
      'Connector Number': `${session.connectorNumber || ''}`,
      'Status': connector ? <p style={{color: statusColor[connector.status.toLowerCase()] }}>{connector.status}</p> : ' - ',
      'Vehicle': session.vehicle,
      'State of Charge': session.soc || ' - ',
      'Id tag': session.idTag,
      'Session Started': dayjs(session.startTime).format('MMM DD, YYYY HH:mm'),
      'Current charge load': session.currentPower ? session.currentPower.toFixed(2) + ' kW' : '-',
      'Total Power Consumption': session.currentPowerConsumption ? session.currentPowerConsumption + ' kWh' : '-',
      "Duration": ((session.duration / 60) / 60).toFixed(2) + ' hrs',
      'Max Capacity': session.maxCapacity + 'kW' || ' - ',
      'Optimized limit': session.optimizedLimit + ' kW' || ' - ',
      '': getMoreIcon(session),
    }
  })
}
