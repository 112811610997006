import axiosCustom from './axios';

export const postLogin = async (userCredentials: { [p: string]: File | string }) => {
  const url = '/users/login';
  try {
    const data = await axiosCustom.post(url, userCredentials);
    return data.data.data;
  } catch (e) {
    console.error('Error in postLogin:: ', e);
    throw e;
  }
};

export const postPasswordReset = async (userCredentials: { [p: string]: File | string }, session: string) => {
  const url = '/users/password-reset';
  try {
    const data = await axiosCustom.post(url, { ...userCredentials, session });
    return data.data.data;
  } catch (e) {
    console.error('Error in postPasswordReset:: ', e);
    throw e;
  }
};
