import axiosCustom from './axios';

const postOcppMessages = async (params: Record<string, unknown>) => {
  const url = '/ocpp-backend';
  try {
    const data = await axiosCustom.post(url, params);
    return data.data.data;
  } catch(e) {
    console.error("Error in postOcppMessages:: ", e);
    throw e;
  }
};

export {
  postOcppMessages,
};
