import axiosCustom from './axios';

const getChargingSessions = async (qsParams?: any) => {
  const url = '/charging-sessions';
  try {
    const data = await axiosCustom.get(url, {params: qsParams});
    return data.data.data ?? [];
  } catch(e) {
    console.error("Error in getChargingSessions:: ", e);
    throw e;
  }
};

const getChargingSessionById = async (id:string) => {
  const url = `/charging-sessions/${id}`;
  try {
    const data = await axiosCustom.get(url);
    return data.data.data;
  } catch(e) {
    console.error("Error in getChargingSessionById:: ", e);
    throw e;
  }
};

export {
  getChargingSessions,
  getChargingSessionById,
};