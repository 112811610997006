import MoreIconList from '../../../components/ellipsis-menu/MoreIconList';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import RotateLeftRoundedIcon from '@mui/icons-material/RotateLeftRounded';
import { requestHardReboot, requestSoftReboot } from '../../../utils/ocppCommands';

const getMoreIcon = (evse: any) => {
  const evseMenu = [
    {
      icon: () => <RotateLeftRoundedIcon color="info"/>,
      title: 'Soft Reboot',
      clickHandler: () => requestSoftReboot(evse.id),
      confirmText: 'Perform a soft reset?'
    },
    {
      icon: () => <RestartAltIcon color="info"/>,
      title: 'Hard Reboot',
      clickHandler: () => requestHardReboot(evse.id),
      confirmText: 'Perform a hard reset?'
    },
  ]
  return <MoreIconList menuItems={evseMenu} />
};

const formatEvse = (evses: any[], groups: any) => {
  return evses.map(((evse: any) => {
    const {
      status,
      connected,
      connectors,
      groupId,
      meterType,
      model,
      serialNumber,
      vendor
    } = evse;

    return {
      'Model': model || '-',
      'Vendor': vendor || '-',
      'Serial Number': serialNumber || '-', 
      'Status': <p style={{margin: 0, color: status === 'Available' ? 'var(--success)' : 'var(--error)'}}>{status}</p>,
      'Connected': <p style={{margin: 0, color: (connected) ? 'var(--success)' : 'var(--error)'}}>{(connected) ? 'ONLINE' : 'OFFLINE'}</p>,
      'Connectors': connectors || '-',
      'Group': groups.length ? groups.find((group: any) => group.id === groupId)?.name : groupId,
      'Meter Type': meterType || '-',
      '': getMoreIcon(evse),
    }
  }));
};

export {
  formatEvse,
};
