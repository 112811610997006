import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  line: {
    height: '2px',
    width: '100%',
    // marginBottom: '50px',
    backgroundColor: '#EDEDED'
  },
});

interface LineProps {
  margin?: string;
}

const Line = ({ margin }: LineProps) => {
  const classes = useStyles();
  return (
    <div className={classes.line} style={{margin: margin ? margin : '0'}}> </div>
  )
};

export default Line;
