import EllipsisIcon from '@mui/icons-material/MoreHoriz';
import Alert from '@mui/material/Alert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../context';
import { requestRemoteStart } from '../../utils/ocppCommands';
import ConfirmDialog from '../confim-dialog/ConfirmDialog';
import IdTagSelect from '../id-tag-select/IdTagSelect';
import { useStyles } from './styles';

type MenuItemData = {
  icon: () => React.ReactNode
  clickHandler: () => Promise<void>
  title: string
  confirmText: string
}

type AlertData = {
  severity: 'error' | 'success'
  message: string
}

const MoreIconList = ({ menuItems, connector }: { menuItems: MenuItemData[], connector?: any }) => {
    const [selectedIdTag, setSelectedIdTag] = useState('');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [openDialog, setOpenDialog] = useState(false);
    const [confirmContent, setConfirmContent] = useState<React.ReactNode>();
    const [confirmAction, setConfirmAction] = useState<() => Promise<void>>();
    const [alert, setAlert] = useState<AlertData | null>(null);
    const [showAlert, setShowAlert] = useState(false);

    const classes = useStyles();

    useEffect(() => {
      if (alert) {
        setShowAlert(true);
      }
    }, [alert]);

    const handleClick = (event: React.MouseEvent<HTMLLIElement | HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleSelectChange = (event: SelectChangeEvent) => {
      setSelectedIdTag(event.target.value);
    };

    const handleClickListItem = (item: MenuItemData) => {
      if (item.title.toLowerCase() === 'start session') {
        setAnchorEl(null);
        setOpenDialog(true);
        setConfirmContent(<IdTagSelect handleChange={handleSelectChange} />);
      } else {
        setAnchorEl(null);
        setOpenDialog(true);
        setConfirmContent(item.confirmText);
        setConfirmAction(() => item.clickHandler);
      }
    };


    const handleClickSubmit = async () => {
      setOpenDialog(false);
      setConfirmContent(null);
      try {
        await confirmAction!();
        setAlert({ severity: 'success', message: 'Request sent successfully' });
      } catch (e) {
        setAlert({ severity: 'error', message: 'Request failed. Please try again later.' });
      }
    };

    const handleClose = () => {
      setOpenDialog(false);
      setAnchorEl(null);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (event.key === 'Enter') {
        setAnchorEl(event.currentTarget);
      }
    };

    useEffect(() => {
      if (selectedIdTag) {
        const handler = () => requestRemoteStart({
          connectorId: connector.connectorNumber,
          chargePointId: connector.chargepointId,
          idTag: selectedIdTag
        })
        setConfirmAction(() => handler);
      }
    }, [selectedIdTag])

    return (
      <div>
        <button
          className={classes.main}
          style={{ backgroundColor: open ? '#1a1a1a1a' : 'transparent' }}
          onClick={(e) => handleClick(e)}
          onKeyDown={(e) => handleKeyDown(e)}
        >
          <EllipsisIcon fontSize="medium" />
        </button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {menuItems.map((item, index) =>
            <MenuItem
              key={`menu-item-${index}`}
              onClick={() => handleClickListItem(item)}
            >
              {item.icon()}
              <span className={classes.menuItemTitle}>{item.title}</span>
            </MenuItem>
          )}
        </Menu>
        <ConfirmDialog
          id="confirm-action"
          keepMounted
          open={openDialog}
          onClose={handleClose}
          onSubmit={handleClickSubmit}
          content={confirmContent}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={showAlert} autoHideDuration={5000}
          onClose={() => {setShowAlert(false);}}
        >
          <Alert
            sx={{ width: '100%' }} onClose={() => setShowAlert(false)} severity={alert?.severity}
          >{alert?.message}</Alert>
        </Snackbar>
      </div>
    );
  }
;

export default MoreIconList;
