import React, { useState, useContext } from 'react';
import api from '../api';
import { Optimization } from '../api/optimizations';
import { Group, MyTenant } from "../api/types";

interface ContextValues {
  devices: any[];
  connectors: any[];
  evses: any[];
  chargingSessions: any[];
  idtags: any[];
  groups: Group[];
  optimizations: Optimization[];
  loadingData: boolean;
  error: any;
  getAllAppData: () => void;
  myTenant: MyTenant | null;
}

const defaultState = {
  chargingSessions: [],
  devices: [],
  connectors: [],
  evses: [],
  idtags: [],
  groups: [],
  optimizations: [],
  getAllAppData: () => null,
  loadingData: false,
  error: false,
  myTenant: null,
}

export const AppContext = React.createContext<ContextValues>(defaultState);

interface ContextProviderProps {
  children: React.ReactNode;
}

export const AppContextProvider = ({ children }: ContextProviderProps) => {
  const [loadingData, setLoadingData] = useState(true);
  const [devices, setDevices] = useState<any>(defaultState.devices);
  const [connectors, setConnectors] = useState<any>(defaultState.connectors);
  const [evses, setEvses] = useState<any>(defaultState.evses);
  const [chargingSessions, setChargingSessions] = useState<any>(defaultState.chargingSessions);
  const [idtags, setIdtags] = useState<any>(defaultState.idtags);
  const [groups, setGroups] = useState<Group[]>([])
  const [optimizations, setOptimizations] = useState<Optimization[]>([])
  const [error, setError] = useState(false);
  const [myTenant, setMyTenant] = useState<MyTenant | null>(null);

  const getAllAppData = async () => {
    try {
      await getAndFormatDevices();
      await getIdTags();
      await getChargingSessions();
      await getGroups();
      await getOptimizations();
      await getMyTenant();
    } catch (e) {
      setError(true)
    } finally {
      setLoadingData(false)
    }
  };
  
  const getAndFormatDevices = async () => {
    try {
      const devices = await api.devices.getDevices();
      const connectorDevices = devices.filter((device: any) => device.type === 'connector');
      const evseDevices = devices.filter((device: any) => device.type === 'chargepoint');
      
      setDevices(devices);
      setConnectors(connectorDevices);
      setEvses(evseDevices);
    } catch (e) {
      throw new Error('Error in getAndFormatDevices:: ', { cause: e });
    }
  };
  
  const getIdTags = async () => {
    try {
      const idtags = await api.idtags.getIdTags();
      setIdtags(idtags);
    } catch (e) {
      throw new Error('Error in getIdTags:: ', { cause: e });
    }
  };
  
  const getChargingSessions = async () => {
    try {
      const cs = await api.chargingSessions.getChargingSessions();
      setChargingSessions(cs);
    } catch (e) {
      throw new Error('Error in getChargingSessions:: ', { cause: e });
    }
  };
  
  const getGroups = async () => {
    try {
      const groups = await api.groups.getAllGroups();
      setGroups(groups);
    } catch (e) {
      throw new Error('Error in getGroups:: ', { cause: e });
    }
  };

  const getOptimizations = async () => {
    try {
      const optimizations = await api.optimizations.getAllOptimizations();
      setOptimizations(optimizations);
    setLoadingData(false);
    } catch (e) {
      throw new Error('Error in getOptimizations:: ', { cause: e });
    }
  };

  const getMyTenant = async () => {
    try {
      const tenant = await api.tenants.getMyTenant();
      setMyTenant(tenant);
    } catch(e) {
      throw new Error('Error in getMyTenant:: ', { cause: e });
    }
  }
  
  return (
    <AppContext.Provider value={{
      loadingData,
      devices,
      evses,
      connectors,
      chargingSessions,
      idtags,
      groups,
      optimizations,
      error,
      getAllAppData,
      myTenant,
    }}>
      {children}
    </AppContext.Provider>
  )
};

export const useAppContext = () => {
  return useContext(AppContext);
};
