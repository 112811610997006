import React from 'react';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import NoDataCard from './NoDataCard';
import ErrorCard from './ErrorCard';
import LoadingTable from './LoadingTable';
import {
  TableRow,
  TableHead,
  TableCell,
} from '@mui/material';

const useStyles = makeStyles({
  tableContainer: {
    padding: '0 16px',
    paddingLeft: '32px',
    margin: '24px 0 0 0',
    height: '100%',
  },
  table: {
    borderSpacing: '20px',
    borderCollapse: 'separate',
  },
  tableRow: {
    backgroundColor: 'var(--bg-light-grey)',
    height: '54px',
  },
  tableData: {
    padding: '0',
    '&:first-child': {
      borderRadius: '8px 0 0 8px'
    },
    '&:last-child': {
      borderRadius: '0 8px 8px 0',
    }
  },
});

type TableValue = string | number | JSX.Element | null;
interface TableData {
  [key: string]: TableValue;
}

interface StandardTableProps {
  data: TableData[];
  tableTop: string | JSX.Element;
  noDataMessage?: string;
  noDataSubtext?: string;
  noDataButtonText?: string;
  noDataButtonIcon?: JSX.Element;
  noDataHandleClick?: () => void;
  loading?: boolean;
  error: boolean;
}

const splitData = (data: TableData[]) => {
  let labels: string[] = [];
  let tableValues: TableValue[][] = [];
  if (data.length) {
    labels = Object.keys(data[0]);
    tableValues = data.map((item) => {
      return Object.values(item);
    });
  }
  return {
    labels, tableValues
  }
};

const StandardTable = ({
  data, noDataMessage,
  noDataSubtext, noDataButtonText,
  noDataButtonIcon, noDataHandleClick,
  loading, error,
}: StandardTableProps) => {
  const classes = useStyles();
  const { labels, tableValues } = splitData(data);

  return (
    <>
    {
      loading ? (
        <LoadingTable numberOfListItems={5}/>
      ) : (
        data.length ? (
          <TableContainer className={classes.tableContainer} >
            <Table stickyHeader className={classes.table} sx={{ minWidth: 500, borderCollapse: 'separate', borderSpacing: '0px 8px', paddingBottom: '58px' }} aria-label="custom pagination table">
              <TableHead sx={{position: 'sticky'}}>
                <TableRow>
                  {
                    labels.map((label, index) =>
                      <TableCell 
                        sx={{
                          color: 'var(--bg-dark-grey)',
                          fontSize: '12px',
                          borderBottom: 'none',
                          paddingTop: '0',
                          paddingBottom: '8px',
                        }}
                        key={`${index}-${label}`}
                      >{label}</TableCell> 
                    )
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  tableValues.map((val, i) => 
                    <TableRow key={i} className={classes.tableRow} >
                      {
                        val.map((value, index) => (
                          <TableCell
                          key={index}
                          className={classes.tableData}
                          style={
                            {
                              borderBottom: 'none',
                              padding: '0px 16px',
                            }
                          }
                          >{value}
                          </TableCell>
                        ))
                      }
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <>
          {
            error ? (
              <ErrorCard errorMessage="An error occured retrieving the data.  Please refresh the page."/>
            ) : (
              <NoDataCard
                message={noDataMessage ? noDataMessage : 'No data found'}
                buttonLabel={noDataButtonText}
                buttonLabelIcon={noDataButtonIcon}
                onclick={noDataHandleClick}
                subtext={noDataSubtext}
              />
            )
          }
          </>
        )
      )
    }
   </>
  );
};

export default StandardTable;
