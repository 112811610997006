const setSessionStorage = (key: string, data: string) => {
  console.debug(`Setting Session Storage with key: ${key}`);
  try {
    const res = window.sessionStorage.setItem(key, data);
    return res
  } catch(e) {
    console.error("ERROR SETTING SESSION STORAGE:: ", e)
  }
};

const getSessionStorage = (key: string) => {
  console.debug(`Checking session storage for ${key} data`);
  try {
    const data = window.sessionStorage.getItem(key);
    if (data) {
      console.debug(`Session storage FOUND for ${key} data`);
      return JSON.parse(data);
    }
  } catch(e) {
    console.error('ERROR checking session storage:: ', e)
  }
};

const getLocalStorage = (key: string) => {
  console.debug(`Checking local storage for ${key} data`);
  try {
    const data = window.localStorage.getItem(key);
    if (data) {
      console.debug(`Local storage FOUND for ${key} data`);
      return JSON.parse(data);
    }
  } catch(e) {
    console.error('ERROR GETTING LOCAL STORAGE:: ', e)
  }
};

const setLocalStorage = (key: string, data: string) => {
  console.debug(`Setting Local Storage with key: ${key}`);
  try {
    return window.localStorage.setItem(key, data);
  } catch(e) {
    console.error("ERROR SETTING LOCAL STORAGE:: ", e)
  }
};

const removeLocalStorage = (key: string) => {
  console.debug(`Removing local storage key: ${key}`);
  try {
    return window.localStorage.removeItem(key);
  } catch(e) {
    console.error("ERROR REMOVING LOCAL STORAGE:: ", e)
  }
};

export {
  setSessionStorage,
  getSessionStorage,
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
};
