import React from 'react';
import { StandardTable } from '../../../components/elements';
import { useAppContext } from '../../../context';
import { formatConnector } from './data';

interface ConnectorsProps {
  connectors: any[];
  evses: any[];
}

const Connectors = ({ connectors, evses }: ConnectorsProps) => {
  const { loadingData, error, groups, chargingSessions } = useAppContext();
  
  return (
    <StandardTable
      data={connectors.length ? formatConnector(connectors, evses, groups, chargingSessions) : []}
      tableTop="Connectors"
      noDataMessage={'No Connectors Found'}
      loading={loadingData}
      error={error}
    />
  );
};

export default Connectors;
