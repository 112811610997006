import { Group } from '../../../api/groups';
import { Optimization } from '../../../api/optimizations';

const months = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']

const formatSchedule = (schedule: string) => {
  const parsedSchedule = schedule.split(' | ');
  if (parsedSchedule.length === 1) {
    return "-"
  }

  let scheduleText = `${parsedSchedule[1]}:${parsedSchedule[0].padStart(2, '0')}`;
  if (parsedSchedule[2] === '*') {
    scheduleText += ' daily';
  } else {
    scheduleText += ` day ${parsedSchedule[2]} of`;
  }

  if (parsedSchedule[3] === '*') {
    scheduleText += ', every month';
  } else {
    scheduleText += `, ${months[Number(parsedSchedule[3]) - 1]}`;
  }

  if (parsedSchedule[4] === '*') {
    scheduleText += ', each day of the week'
  } else {
    const parsedDays = parsedSchedule[4].split('-')

    if (parsedDays.length === 1) {
      scheduleText += `, on ${days[Number(parsedDays[0])]}`
    } else {
      scheduleText += `, ${days[Number(parsedDays[0])]} thru ${days[Number(parsedDays[1])]}`
    }
  }

  return scheduleText
};

const formatOptimizations = (optimizations?: Optimization[], groups?: Group[]) => {
  if (!optimizations) {
    return []
  }

  return optimizations.map(((opt: Optimization) => {
    const {
      name,
      operatingLimit,
      enabled,
      groupId,
      schedule,
      startDate,
      endDate,
      durationInHours,
    } = opt;

    return {
      'Name': name,
      'Operating Limit': `${operatingLimit}`,
      'Enabled': enabled ? 'Enabled' : 'Disabled',
      'Group': groups?.find(g => g.id === groupId)?.name ?? '0',
      'Schedule': formatSchedule(schedule),
      'Start Date': startDate ? Date.parse(startDate) : '-',
      'End Date': endDate ? Date.parse(endDate) : '-',
      'Duration': `${durationInHours} Hours`,
    };
  }));
};

export {
  formatOptimizations,
};

