import React from 'react';
import { StandardTable } from '../../../components/elements';
import { useAppContext } from '../../../context';
import { formatIdTag } from './data';

interface IdTagsProps {
  idTags: any;
}

const IdTags = ({ idTags }: IdTagsProps) => {
  const { error, loadingData } = useAppContext();
  
  return (
    <StandardTable
      data={idTags.length ? formatIdTag(idTags) : []}
      tableTop="ID Tags"
      noDataMessage="No ID Tags Found"
      loading={loadingData}
      error={error}
    />
  );
};

export default IdTags;
