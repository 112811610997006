import { postOcppMessages } from '../api/ocppMessages';

const triggerMessage = (message: string, connectorId: string, chargePointId: string) => ({
  chargePointId,
  ocppMessage: [2, 'system', 'TriggerMessage', { requestedMessage: message, connectorId }],
});

export const requestRemoteStop = (chargePointId: string, transactionId: string) => postOcppMessages({
  chargePointId,
  ocppMessage: [2, "system", "RemoteStopTransaction", { transactionId }]
});


export const requestRemoteStart = ({ chargePointId, connectorId, idTag }: {
  chargePointId: string,
  connectorId: string,
  idTag: string
}) => postOcppMessages({
  chargePointId,
  ocppMessage: [2, 'system', 'RemoteStartTransaction', { connectorId, idTag }]
});

export const requestSoftReboot = (chargePointId: string) => postOcppMessages({
  chargePointId,
  ocppMessage: [2, 'system', 'Reset', { type: 'Soft' }]
});

export const requestHardReboot = (chargePointId: string) => postOcppMessages({
  chargePointId,
  ocppMessage: [2, 'system', 'Reset', { type: 'Hard' }]
});

export const requestMeterValues = ({ connectorId, chargePointId }: {
  connectorId: string,
  chargePointId: string,
}) =>
  postOcppMessages(triggerMessage('MeterValues', connectorId, chargePointId));

export const requestStatusNotification = ({ connectorId, chargePointId }: {
  connectorId: string,
  chargePointId: string,
}) =>
  postOcppMessages(triggerMessage('StatusNotification', connectorId, chargePointId));
