import React, { useEffect, useState } from 'react';
import { Loading } from '../../components/elements';
import SummaryHeader from '../../components/summary-header';
import BarGraph from './BarGraph';
import DataDisplay from './DataDisplay';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';
import api from '../../api';
import { processSocValues } from './data';

const useStyles = createUseStyles({
  main: {
    backgroundColor: 'var(--bg-light-grey)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  graphBox: {
    height: '40%',
  },
  dataDisplayBox: {
    height: '50%',
  }
});


const ChargingSession = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [meterValues, setMeterValues] = useState<any>();
  const [chargingSession, setChargingSession] = useState<any>();
  const [socValues, setSocValues] = useState<any>();

  const getChargingSession = async () => {
    try {
      const chargingSession = await api.chargingSessions.getChargingSessions({ transactionId: id });
      if (chargingSession.length) {
        console.log("CHATRGING SESSION:: ", chargingSession);
        setChargingSession(chargingSession[0]);
      }
    } catch (e) {
      console.error('Error in getChargingSession():: ', e);
    }
  };

  const getMeterValues = async () => {
    try {
      const meterValues = await api.meterValues.getMeterValuesByTransactionId({ transactionId: id, limit: '200' });
      if (meterValues) {
        const processeedcSocValues = processSocValues(meterValues);
        setSocValues(processeedcSocValues);
        setMeterValues(meterValues);
      }
    } catch (e) {
      console.error("Error getting meter values: ", e);
    }
  };

  useEffect(() => {
    if (!socValues && id) {
      getMeterValues();
      getChargingSession();
    }
  }, [meterValues, chargingSession, id]);

  return (
    <div className={classes.main}>
      <SummaryHeader
        header="Charging Session Details"
        data={[]}
      />
      <div
        style={{
          backgroundColor: '#fff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50%',
          borderBottom: '1px solid var(--border-light)'
        }}
      >
        {
          !socValues ?
            <Loading color="#00AEEF" size="4rem" />
            :
            <BarGraph socValues={socValues} />
        }
      </div>
      {
        chargingSession && meterValues ?
          <div className={classes.dataDisplayBox}>
            <DataDisplay chargingSession={chargingSession} meterValues={meterValues} />
          </div> : null
      }
    </div>
  );
};

export default ChargingSession;
