import PlayIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { useState } from 'react';
import { useAppContext } from '../../context';

type IdTag = {
  id: string
  vehicle: string
}

interface Props {
  handleChange: (event: SelectChangeEvent) => void
};

export default function IdTagSelect({ handleChange }: Props) {
  const { idtags: idTags } = useAppContext();
  const [selectedId, setSelectedId] = useState<any>(idTags[0].id);

  return <div>
    <p>Start Charging Session</p>
    <FormControl>
      <InputLabel id="id-tag-select-label">Vehicle</InputLabel>
      <Select
        labelId="id-tag-select-label"
        id="id-tag-select"
        value={selectedId}
        label="Vehicle"
        onChange={(e) => {
          setSelectedId(e.target.value)
          handleChange(e)
        }}
      >
        {idTags.map((idTag) =>
          <MenuItem
            key={idTag.id}
            value={idTag.id}
          >
            {idTag.vehicle}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  </div>;
}
