import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from "@mui/system";
import { useMediaQuery } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

type DashboardCardData = {
  label: string;
  value: React.JSX.Element | string | null;
  valueMetric?: string;
};

const useStyles = makeStyles({
  cardContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    border: '1px solid #fff',
    color: 'var(--black)',
    width: 'auto',
    borderRadius: '16px',
    boxShadow: '1px 1px 2px 0 var(--border-light)',
    '&:hover': {
      border: '1px solid var(--accent-light-blue)'
    }
  },
  cardContent: {
    padding: ' 0 20px 10px 20px',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  cardTitle: {
    fontSize: '16px',
  },
  subtitle: {
    fontSize: '11px',
    color: 'var(--bg-dark-grey)',
    marginLeft: '-8px',
  },
  dataContainer: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'space-between',
    minWidth: '70px',
  },
  dataBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    justifyContent: 'space-between',
  },
  dataLabel: {
    fontSize: '13px',
    color: 'var(--bg-dark-grey)',
    textTransform: 'capitalize',
    margin: 0,
  },
  smallText: {
    color: 'var(--black)',
    fontSize: '12px',
  },
  textContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.18)',
    borderRadius: '20px',
    height: '20px',
    padding: '0px 8px',
    alignItems: 'center',
  },
});

interface DashboardCardProps {
  cardTitle: string;
  subtitle?: string;
  data: DashboardCardData[]
  timeframe?: string;
  metric?: string;
  button?: React.JSX.Element;
}

const DashboardCard: React.FC<DashboardCardProps> = ({ cardTitle, subtitle, data, metric, timeframe, button }) => {
  const classes = useStyles();
  const theme = useTheme();
  const belowLg = useMediaQuery(theme.breakpoints.down('lg'));
  
  return (
    <div className={classes.cardContainer}>
      <div className={classes.cardContent}>
        <div className={classes.titleContainer}>
          <div className={classes.titleBox}>
            <p className={classes.cardTitle}>{cardTitle}</p>
            {
              subtitle ?
                <p className={classes.subtitle}>{subtitle}</p>
                : null
            }
            {timeframe ?
              <p className={classes.smallText}>{timeframe}</p>
              : null
            }
          </div>
          {
            !belowLg && button && button
          }
          {
            belowLg && button && <EditOutlinedIcon fontSize='small' htmlColor="#FFF"/>
          }
        </div>
        <div className={classes.dataContainer}>
          {
            data.length ?
              data.map(({ label, value, valueMetric }: DashboardCardData, i: number) => (
                <div className={classes.dataBlock} key={i}>
                  <p className={classes.dataLabel}>{label}</p>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', flexGrow: 1}}>
                    {value}
                    {
                      valueMetric ?
                        <div className={classes.textContainer} style={{ marginLeft: '8px' }}>
                          <p className={classes.smallText}>{valueMetric}</p>
                        </div>
                        : null
                    }
                  </div>
                  {metric ?
                    <div className={classes.textContainer}>
                      <p className={classes.smallText}>{metric}</p>
                    </div>
                    : null
                  }
                </div>
              )) : null
          }
        </div>
      </div>
    </div>
  )
};

export default DashboardCard;
