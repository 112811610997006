import React from 'react';
import PlaceholderSkeleton from './PlaceholderSkeleton';

interface LoadingTableProps {
  numberOfListItems: number;
}

const LoadingTable: React.FC<LoadingTableProps> = ({numberOfListItems}) => (
  <div style={{padding: '32px'}}>
    {
    Array(numberOfListItems).fill('').map((x, index) => (
      <div key={index}>
        <PlaceholderSkeleton
          variant='rectangular'
          style={{ width: '100%', borderRadius: '16px', backgroundColor: '#F6F7F7', marginBottom: '10px' }}
          height={50}
        />
      </div>
    ))
    }
  </div>
);

export default LoadingTable;