import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  tabMain: {
    width: '100%',
    paddingLeft: '20px',
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: 'var(--bg-light-grey)',
    borderTop: '1px solid var(--bg-dark-grey)'
  },
  tabDivider: {
    height: 'calc(100% - 24px)',
    width: '1px',
    marginTop: '12px',
    background: 'var(--border-light)'
  },
  tab: {
    display: 'inline-block',
    color: 'var(--bg-dark-grey)',
    fontSize: '16px',
    padding: '12px 16px',
    cursor: 'pointer',
    position: 'relative',
    '&:after': {
      background: 'none repeat scroll 0 0 transparent',
      bottom: 0,
      content: "''",
      display: 'block',
      height: '4px',
      left: '50%',
      position: 'absolute',
      backgroundColor: 'var(--accent-light-blue)',
      transition: 'width 0.3s ease 0s, left 0.3s ease 0s',
      width: 0,
    },
    '&:hover:after': {
      width: 'calc(100% - 12px)',
      left: '6px',
    },
    '&::before': {
      content: 'attr(data-text)',
      display: 'block',
      height: 0,
      visibility: 'hidden',
      overflow: 'hidden',
      userSelect: 'none',
      pointerEvents: 'none',
      fontWeight: 600
    }
  },
  activeTab: {
    backgroundColor: '#fff',
    color: 'var(--black)',
    fontWeight: '600',
    '&:after': {
      width: 'calc(100% - 12px)',
      left: '6px'
    }
  },
});