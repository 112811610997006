import axiosCustom from './axios';

export type Group = {
    id: string
    name: string
    tenantId: string
}

export const getAllGroups = async (): Promise<Group[]> => {
    const url = '/groups';
    try {
        const data = await axiosCustom.get(url)
        return data.data.data ?? [];
    } catch(e) {
        console.error("Error in getAllGroups:: ", e);
        throw e;
    }
};

export const getGroupsById = async (id:string): Promise<Group[]> => {
    const url = `/groups/${id}`;
    try {
        const data = await axiosCustom.get(url);
        return data.data.data;
    } catch(e) {
        console.error("Error in getGroupsById:: ", e);
        throw e;
    }
};
