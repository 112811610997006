import React from 'react';
import { StandardTable } from '../../../components/elements';
import { formatActiveSessionTableData } from './data';
import { useAppContext } from '../../../context';

interface ActiveSessionsTableProps {
  chargingSessions: any;
  evses: any;
}

const ChargingSessionsTable = ({ chargingSessions, evses }: ActiveSessionsTableProps) => {
  const { error, loadingData } = useAppContext();
  
  return (
    <StandardTable
      data={chargingSessions.length ? formatActiveSessionTableData(chargingSessions, evses) : []}
      tableTop={'Charging Sessions'}
      loading={loadingData}
      error={error}
    />
  );
};

export default ChargingSessionsTable;
