import React, { createContext } from 'react';
import { useProvideAuth } from '../hooks/useAuth';

export type LoginResponse = {
  IdToken: string
  ChallengeName: string
  Session: string
}

type AuthContextValues = {
  token?: string,
  login: (creds: { [p: string]: File | string }) => Promise<LoginResponse>,
  logout: () => void,
  resetPassword: (creds: { [p: string]: File | string }, session: string) => Promise<LoginResponse>,
  verifyToken: () => string | null,
}

// @ts-expect-error
export const AuthContext = createContext<AuthContextValues>();

export default function ProvideAuth({ children }: { children: React.ReactNode }) {
  const auth = useProvideAuth();

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
}
