import axiosCustom from './axios';

const getDevices = async (qparams?: any) => {
  const url = '/devices';
  try {
    const data = await axiosCustom.get(url)
    return data.data.data;
  } catch(e) {
    console.error("Error in getDevices:: ", e);
    throw e;
  }
};

const getDeviceById = async (id:string) => {
  const url = `/devices/${id}`;
  try {
    const data = await axiosCustom.get(url);
    return data.data.data;
  } catch(e) {
    console.error("Error in getDeviceById:: ", e);
    throw e;
  }
};

export {
  getDevices,
  getDeviceById,
};