
const formatIdTag = (idTags:any) => {
  return idTags.map(((tag:any) => {
    const {
      id,
      idTag,
      isActive,
      vehicle,
      expiryDate,
      inUse,
    } = tag;
    // const matchedConnectors = depot.connectors.filter(connector => connector.evseId === evse.id);
    // const matchedChargingSessions = depot.chargingSessions.filter(session => session.evseId === evse.id);
    // const timeframe = ((60 * 60000) * 24);
    // const { currentTotalUtilization } = calculateUtilization(matchedChargingSessions, matchedConnectors.length, timeframe);
    return {
      'Id Tag': idTag || '-',
      'Vehicle': vehicle || '-',
      'Expiration Date': expiryDate || '-',
      'in Use': inUse.toString().toUpperCase(),
      'Active': <p style={{margin: 0, color: (isActive) ? '#64D08F' : '#FF5630'}}>{(isActive).toString().toUpperCase()}</p>,
    }
  }));
};

export {
  formatIdTag,
};
