
const sortByTimestamp = (a: any, b: any) => {
  if (a.timestamp > b.timestamp) return 1;
  return -1
}

export const processSocValues = (meterValues: any) => {
  console.log("METERVALUES RECEIVED:: ", meterValues.length);
  const socValues = meterValues.map((meterValue: any) => {
      return meterValue.meterValue[0].sampledValue.map((value: any) => {
        if (value.measurand === 'SoC') {
          return {
            soc: value,
            timestamp: meterValue.meterValue[0].timestamp,
          }
        }
      });
  }).flat(2).filter((val: any) => val).sort((a: any, b: any) => sortByTimestamp(a, b));

  return socValues;
};

export const processPowerValues = (meterValues: any) => {
  const powerValues = meterValues.map((meterValue: any) => {
    return meterValue.meterValue[0].sampledValue.map((value: any, index: number) => {
      if (value.measurand === "Energy.Active.Import.Register") {
        return {
          power: value,
          timestamp: meterValue.meterValue[0].timestamp,
        }
      }
    });
  }).flat(2).filter((val: any) => val).sort((a: any, b: any) => sortByTimestamp(a, b));
return powerValues;
};

export const mockSession = {
  "powerOffered": "75000.0",
  "chargingProfileId": 749519054,
  "totalPowerConsumption": 60799,
  "tenantId": "58c0115d-074f-4e0c-90d5-768d255ebe27",
  "groupId": "d48a14be-0b46-4cae-932b-2a4fe8d01186",
  "evseId": "425dcb7f-8fb8-4a29-a252-7695fa79847e",
  "meterStart": 0,
  "vehicle": "Hyundai Kona",
  "maxCapacity": 75,
  "connectorNumber": 2,
  "reservationId": null,
  "meterStop": 60799,
  "connectorId": "54942423-4207-492e-8002-7b1d45b8731b",
  "currentPowerConsumption": 0,
  "endingSoc": "99.0",
  "id": "e0c10f39-38b2-4dd2-b908-da20deec8b4c",
  "duration": 6367.005,
  "startingSoc": "15.0",
  "soc": "99.0",
  "timestamp": 1694627313014,
  "created": "2023-09-13T17:48:32.634Z",
  "transactionId": 942040873,
  "startTime": "2023-09-13T17:48:32.634Z",
  "optimizedLimit": 75,
  "isActive": 0,
  "updated": "2023-09-13T19:34:39.639Z",
  "endTime": "2023-09-13T19:34:39.639Z",
  "idTag": "0A8B7E7E",
  "currentPower": 0,
  "connectionId": "veefil-602300631"
};