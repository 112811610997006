import * as devices from './devices';
import * as chargingSessions from './charging_sessions';
import * as idtags from './idTags';
import * as groups from './groups'
import * as users from './users'
import * as tenants from './tenants'
import * as meterValues from './meter_values'
import * as optimizations from './optimizations';
import * as ocppMessages from './ocppMessages';

export default {
  devices,
  chargingSessions,
  idtags,
  groups,
  optimizations,
  users,
  tenants,
  meterValues,
  ocppMessages
};
