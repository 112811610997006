import React from 'react';
import { useAppContext } from '../../context';
import { StandardTable } from '../../components/elements';

const formatEvseData = (evses: any, groups: any) => {
  
  return evses.map((evse: any) => {
    const group = groups.find((group: any) => group.id === evse.groupId);
    return {
      "Vendor": evse.vendor,
      "Model": evse.model,
      'Status': <p style={{ margin: 0, color: evse.status === 'Available' ? '#64D08F' : '#FF5630' }}>{evse.status}</p>,
      'Connected': <p style={{
        margin: 0,
        color: (evse.connected) ? '#64D08F' : '#FF5630'
      }}>{(evse.connected) ? 'ONLINE' : 'OFFLINE'}</p>,
      'Group': group ? group.name : 'None'
    }
  })
};

const EvseList = (): React.ReactElement => {
  const { evses, groups, loadingData } = useAppContext();
  
  return (
    <StandardTable
      tableTop="Evses"
      data={formatEvseData(evses, groups)}
      loading={loadingData}
      error={!(evses.length)}
    />
  );
};

export default EvseList;
