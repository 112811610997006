import React from 'react';
import { DepotDetails, HomePage } from '../pages';
import ChargingSessionInsights from '../pages/charging-session-insights/ChargingSessionInsights';

export interface Route {
  label: string;
  endpoint: string;
  component: React.FC | React.ElementType;
};

export const routes: Route[] = [
  {
    label: 'Home',
    endpoint: '/',
    component: HomePage,
  },
  {
    label: 'Details',
    endpoint: '/details',
    component: DepotDetails,
  },
  {
    label: 'Charging Session',
    endpoint: '/charging-session/:id',
    component: ChargingSessionInsights,
  }
];
