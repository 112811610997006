import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AppContextProvider } from './context';
import ProvideAuth from './context/AuthContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ProvideAuth>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </ProvideAuth>
);
