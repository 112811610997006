import React, { useContext, useEffect, useState } from 'react';
import { useAppContext } from '../../context';
import { createUseStyles } from 'react-jss';
import ActiveSessionsTable from './active-sessions/ActiveSessionsTable';
import ChargingSessionsTable from './charging-sessions/ChargingSessionsTable';
import SummaryHeader from '../../components/summary-header';
import { useSearchParams } from 'react-router-dom';
import Connectors from './connectors';
import Evses from './evses';
import IdTags from './idTags';
import OptimizationsTable from './optimizations';
import { GroupContext } from '../../context/GroupContext';
import Tabs from '../../components/tab-component/Tabs';

const useStyles = createUseStyles({
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  body: {
    flexGrow: 1,
    overflowY: 'auto'
  }
});

const DepotDetails = () => {
  const classes = useStyles();
  const { idtags, chargingSessions, connectors, evses, optimizations } = useAppContext();
  const { currentGroup } = useContext(GroupContext);
  
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab') ?? '1';
  const [activeTab, setActiveTab] = useState<number>(Number(tab));
  
  const filteredConnectors = connectors.filter((c) => currentGroup ? c.groupId === currentGroup.id : true)
  const filteredEvses = evses.filter((e) => currentGroup ? e.groupId === currentGroup.id : true)
  const filteredSessions = chargingSessions.filter(cs => currentGroup ? cs.groupId === currentGroup.id : true)
  const filteredOptimizations = optimizations.filter(opt => currentGroup ? opt.groupId === currentGroup.id : true)
  
  useEffect(() => {
    setSearchParams({ tab: activeTab.toString(), groupId: currentGroup?.id ?? 'all' });
  }, [chargingSessions, connectors, evses, idtags, activeTab, currentGroup]); // eslint-disable-line react-hooks/exhaustive-deps
  
  const tabComponents = [
    {
      tabLabel: `Active Charging Sessions (${filteredSessions.filter(({ isActive }) => isActive).length})`,
      component: <ActiveSessionsTable chargingSessions={filteredSessions.filter(({ isActive }) => isActive)}
        evses={filteredEvses} connectors={filteredConnectors}/>,
    },
    {
      tabLabel: `Charging Sessions (${filteredSessions.filter(({ isActive }) => !isActive).length})`,
      component: <ChargingSessionsTable chargingSessions={filteredSessions.filter(({ isActive }) => !isActive)}
        evses={filteredEvses}/>,
    },
    {
      tabLabel: `EVSEs (${filteredEvses.length ? filteredEvses.length : 0})`,
      component: <Evses evses={filteredEvses}/>,
    },
    {
      tabLabel: `Connectors (${filteredConnectors.length ? filteredConnectors.length : 0})`,
      component: <Connectors connectors={filteredConnectors} evses={filteredEvses}/>
    },
    {
      tabLabel: `Id Tags (${idtags.length ? idtags.length : 0})`,
      component: <IdTags idTags={idtags}/>
    },
    {
      tabLabel: `Optimizations (${optimizations.length})`,
      component: <OptimizationsTable optimizations={filteredOptimizations}/>
    },
  ];
  
  return (
    <div className={classes.main}>
      <SummaryHeader
        data={[]}
      />
      <Tabs
        active={activeTab - 1}
        tabs={tabComponents.map(({ tabLabel }) => tabLabel)}
        setActive={setActiveTab}
      />
      <div className={classes.body}>
        {tabComponents[activeTab - 1].component}
      </div>
    </div>
  )
};

export default DepotDetails;
