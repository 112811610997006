import { createTheme } from '@mui/material';

export const customTheme = createTheme({
  palette: {
    primary: {
      main: '#47474a',
    },
    secondary: {
      main: '#f1f3fc'
    },
    error: {
      main: '#ff1f44'
    },
    success: {
      main: '#12c600'
    },
    warning: {
      main: '#ffb700'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 1025,
      md: 1280,
      lg: 1440,
      xl: 1600,
    },
  },
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          "&.MuiLinearProgress-colorPrimary": {
            backgroundColor: '#EBF9FE'
          },
          "&.MuiLinearProgress-barColorPrimary": {
            backgroundColor: '#EBF9FE !important'
          },
        },
        barColorPrimary: { backgroundColor: '#0041c0 !important' },
        colorPrimary: { backgroundColor: '#EBF9FE !important' },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            border: "none"
          },
          borderRadius: '20px',
          backgroundColor: '#FFF',
          height: '46px',
          textAlign: 'left',
          margin: '5px 0px'
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before": {
            borderBottom: 'none'
          },
          "&::before": {
            borderBottom: 'none'
          },
          "&::after": {
            borderBottom: 'none'
          },
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: '#f1f3fc',
          "&::before": {
            borderBottom: '1px solid #f1f3fc'
          },
          "&::after": {
            borderBottom: '1px solid #f1f3fc'
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: '2px solid #f1f3fc'
          },
          padding: '10px 16px',
          height: '46px',
          textAlign: 'left',
          margin: '5px 0px'
        }
      }
    },
  }
});
