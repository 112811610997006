import { useContext, useState } from "react";
import { AuthContext } from '../context/AuthContext';
import * as storage from '../utils/storage';
import api from "../api";

export const localStorageKey = 'pcmsToken';

export const useAuth = () => {
  return useContext(AuthContext);
}

export const useProvideAuth = () => {
  const [token, setToken] = useState(storage.getLocalStorage(localStorageKey));

  const login = async (credentials: { [p: string]: File | string }) => {
    try {
      const loginRes = await api.users.postLogin(credentials);

      if (loginRes.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
        return loginRes
      }

      storage.setLocalStorage(localStorageKey, JSON.stringify(loginRes.IdToken));
      setToken(loginRes.IdToken);

      return loginRes
    } catch (e) {
      console.error('Error in AuthContext login:: ', e);
      throw e;
    }
  };

  const logout = () => {
    setToken(undefined);
    window.localStorage.clear();
    window.sessionStorage.clear();
  };

  const verifyToken = (): string | null => {
    const tokenInStorage = storage.getLocalStorage(localStorageKey);

    if (!tokenInStorage) {
      logout();
      return null
    }

    return tokenInStorage
  };

  const resetPassword = async (userCredentials: { [p: string]: File | string }, loginSession: string) => {
    try {
      const loginRes = await api.users.postPasswordReset(userCredentials, loginSession);
      storage.setLocalStorage(localStorageKey, JSON.stringify(loginRes.IdToken));
      setToken(storage.getLocalStorage(localStorageKey));
      return loginRes
    } catch (e) {
      console.error('Error in AuthContext login:: ', e);
      throw e;
    }
  }

  return {
    token,
    login,
    logout,
    resetPassword,
    verifyToken,
  }
};
