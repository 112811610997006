import React from 'react';
import { useStyles } from './styles';

interface TabsProps {
  tabs: (string | React.ReactElement)[];
  active: number;
  setActive: (val: number) => void;
}

const Tabs = ({ tabs, active, setActive }: TabsProps): React.ReactElement => {
  const classes = useStyles();
  const handleClick = (idx: number) => {
    setActive(idx + 1);
  };
  
  return (
    <div className={classes.tabMain}>
      {
        tabs.map((tab: string | React.ReactElement, index: number) =>
          <React.Fragment key={index}>
            {index === 0 ? <div className={classes.tabDivider}></div> : null}
            <div
              className={[classes.tab, active === index ? classes.activeTab : ''].join(' ')}
              role="button"
              tabIndex={index}
              onKeyDown={() => handleClick(index)}
              onClick={() => handleClick(index)}
              data-text={tab}
            >
              {tab}
            </div>
            <div className={classes.tabDivider}></div>
          </React.Fragment>
        )
      }
    </div>
  )
};

export default Tabs;