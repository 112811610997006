import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  main: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: 'var(--bg-light-grey);'
  },
  dataCard: {
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    border: '1px solid #fff',
    color: 'var(--bg-dark-grey)',
    borderRadius: '16px',
    margin: '16px',
    padding: '16px',
    width: '30%',
    boxShadow: '1px 1px 2px 0 var(--border-light)'
  },
  dataLabel: {
    fontSize: '18px',
    fontWeight: 700,
  },
  dataValue: {
    fontSize: '18px',
  },
  dataBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px'
  },
  cardTitle: {
    fontSize: '24px',
    fontWeight: 700,
  }
});
