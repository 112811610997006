import { createContext, Dispatch, SetStateAction } from 'react'
import { Group } from '../api/groups';

type GroupContextValues = {
  currentGroup: Group | null
  setCurrentGroup: Dispatch<SetStateAction<Group | null>>
  allGroups: Group[]
  setAllGroups: Dispatch<SetStateAction<Group[]>>
}

export const GroupContext = createContext<GroupContextValues>({
  currentGroup: null,
  setCurrentGroup: () => {},
  allGroups: [],
  setAllGroups: () => {}
})
