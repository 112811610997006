import React from 'react';
import { StandardTable } from '../../../components/elements';
import { useAppContext } from '../../../context';
import { formatGroups } from './data';

const GroupsTable = () => {
  const { groups, error, loadingData, evses, myTenant, chargingSessions } = useAppContext();
  
  return (
    <StandardTable
      data={groups ? formatGroups(groups, evses, myTenant, chargingSessions) : []}
      tableTop="Groups"
      loading={loadingData}
      error={error}
    />
  );
};

export default GroupsTable;
