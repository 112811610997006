import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import SummaryHeader from '../../components/summary-header';
import { useAppContext } from '../../context';
import EvseList from './EvseList';
import ActiveSessionsList from './ActiveSessionsList';
import GroupsTable from '../depot-details/groups/GroupsTable';
import SystemHealth from './system-health/SystemHealth';
import Tabs from '../../components/tab-component/Tabs';

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  body: {
    height: '100%',
    gridTemplateColumns: 'repeat(2, 1fr)',
    display: 'grid',
    flexGrow: 1,
  },
  lists: {

  },
  health: {
    height: '100%'
  }

});

type TabComponent = {
  tabLabel: string;
  component: React.ReactElement;
}

const tabComponents: TabComponent[] = [
  {
    tabLabel: `EVSEs`,
    component: <EvseList />,
  },
  {
    tabLabel: `Active Sessions`,
    component: <ActiveSessionsList />,
  },
  {
    tabLabel: 'Groups',
    component: <GroupsTable />
  },
];

const HomePage = (): React.ReactElement => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const [activeTab, setActiveTab] = useState<number>(Number(tab) || 1);
  const { myTenant } = useAppContext();

  useEffect(() => {
    if (!tab) {
      setSearchParams({ tab: '1' });
    } else {
      setSearchParams({ tab: activeTab.toString() });
    }
  }, [activeTab]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className={classes.main}>
      <SummaryHeader
        header={`Welcome, ${myTenant ? myTenant.name : 'Customer'}`}
        data={[]}
      />
      <Tabs
        active={activeTab - 1}
        tabs={tabComponents.map(({ tabLabel }) => tabLabel)}
        setActive={setActiveTab}
      />
      <div className={classes.body}>
        <div className={classes.lists}>
          {tabComponents[activeTab - 1].component}
        </div>
        <div className={classes.health}>
          <SystemHealth />
        </div>
      </div>
    </div>
  );
};

export default HomePage;