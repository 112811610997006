const formatGroups = (groups:any, evses: any, myTenant: any, chargingSessions: any) => {
  return groups.map(((group:any) => {
    const numOfEvses = evses.filter((evse: any) => evse.groupId === group.id);
    const groupChargingSessions = chargingSessions.filter((cs: any) => cs.groupId === group.id);
    const activeSessions = groupChargingSessions.length ? groupChargingSessions.filter((cs: any) => cs.isActive) : [];
    const currentLoad = activeSessions.length ? activeSessions.reduce((acc: number, curr: any) => {
      acc += Number(curr.currentPower);
      return acc;
    }, 0) : 0;

    const {
      name,
    } = group;
    return {
      'Name': name,
      'Evses': numOfEvses.length ?? 0,
      'Active Sessions': activeSessions.length,
      'Current Load': currentLoad.toFixed(2) + ' kW',
    }
  }));
};

export {
  formatGroups,
};
