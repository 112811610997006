import axiosCustom from './axios';

const getIdTags = async (qparams?: any) => {
  const url = '/id-tags';
  try {
    const data = await axiosCustom.get(url)
    return data.data.data;
  } catch(e) {
    console.error("Error in getIdTags:: ", e);
    throw e;
  }
};

const getIdTagById = async (id:string) => {
  const url = `/id-tags/${id}`;
  try {
    const data = await axiosCustom.get(url);
    return data.data.data;
  } catch(e) {
    console.error("Error in getIdTagById:: ", e);
    throw e;
  }
};

export {
  getIdTags,
  getIdTagById,
};