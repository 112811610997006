import React from "react";
import { Skeleton } from "@mui/material";

interface SkeletonProps {
  variant: "text" | "circular" | "rectangular" | undefined;
  style?: React.CSSProperties;
  width?: number;
  height: number;
};

const PlaceholderSkeleton: React.FC<SkeletonProps> = ({ variant, width, height, style }) => (
  <Skeleton 
    variant={variant}
    style={style}
    animation="wave"
    width={width}
    height={height}
  />
)

export default PlaceholderSkeleton;
