import React from 'react';
import { StandardTable } from '../../../components/elements';
import { formatActiveSessionTableData } from './data';
import { useAppContext } from '../../../context';

interface ActiveSessionsTableProps {
  chargingSessions: any;
  evses: any;
  connectors: any;
}

const ActiveSessionsTable = ({ chargingSessions, evses, connectors }: ActiveSessionsTableProps) => {
  const { error, loadingData } = useAppContext();
  
  return (
    <StandardTable
      data={chargingSessions.length ? formatActiveSessionTableData(chargingSessions, evses, connectors) : []}
      tableTop={'Active Sessions'}
      loading={loadingData}
      error={error}
    />
  );
};

export default ActiveSessionsTable;
