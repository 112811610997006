import React, { useEffect, useState, useRef } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  yAxis: {
    transform: 'rotate(-90deg)',
    whiteSpace: 'nowrap',
    width: '12px',
  },
  yAxisLabels: {
    color: 'var(--bg-dark-grey)',
    margin: '0 12px',
    borderRight: '1px solid var(--bg-dark-grey)',
  },
  yLabel: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dataContainer: {
    position: 'relative',
    backgroundColor: '#fff',
    height: '100%',
    width: 'calc(100% - 48px)',
    display: 'flex',
    padding: '24px',
    alignItems: 'flex-end',
    '&::after': {
      content: "''",
      position: 'absolute',
      bottom: '24px',
      borderBottom: '1px solid var(--bg-dark-grey)',
      height: '1px',
      width: 'calc(100% - 48px)'
    }
  }
});

const labels = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

interface SocGraphProps {
  socValues: any[];
}

const SocGraph: React.FC<SocGraphProps> = ({ socValues }) => {
  const graphContainer = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [graphData, setGraphData] = useState<any>([]);
  const [yLabelData, setYLabelData] = useState<any>([]);
  const classes = useStyles();

  const buildGraph = (socValues: any, screenWidth: number, screenHeight: number) => {
    const itemWidth = (screenWidth - 48) / socValues.length;
    setYLabelData(
      labels.map((label: number) => {
        return {
          label: label.toString(),
          height: 100 / labels.length,
        };
      }).reverse()
    );
    setGraphData(socValues.map((el: any) => {
      return {
        soc: el.soc.value,
        timestamp: el.timestamp,
        width: itemWidth - 2,
      };
    }));
  };

  const handleResize = () => {
    if (graphContainer && graphContainer.current) {
      setWidth(window.innerWidth - 48);
      setHeight(window.innerHeight);
    }
  };

  const handleMouseOver = (e: any) => {
    if (e.target.id === 'data-bar') {
      e.target.firstElementChild.style.fontSize = '20px';
      e.target.firstElementChild.style.marginTop = '-32px';
      e.target.firstElementChild.style.marginLeft = '-1em';
      e.target.firstElementChild.style.fontWeight = 700;
      e.target.firstElementChild.style.color = 'var(--bg-dark-grey)';
    }
  };

  const handleMouseLeave = (e: any) => {
    if (e.target.id === 'data-bar') {
      e.target.firstElementChild.style.fontSize = '8px';
      e.target.firstElementChild.style.marginTop = '0';
      e.target.firstElementChild.style.marginLeft = '-2em';
      e.target.firstElementChild.style.fontWeight = 400;
      e.target.firstElementChild.style.color = '#fff';
    }
  };

  useEffect(() => {
    if (width && socValues && socValues.length) {
      buildGraph(socValues, width, height);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [height, width, socValues, graphContainer]);

  return (

    <div className={classes.dataContainer} ref={graphContainer}>
      <div className={classes.yAxis}>
        &nbsp;% Charged
      </div>
      <div className={classes.yAxisLabels}>
        {
          yLabelData.length && yLabelData.map((label: any, index: number) =>
            <p key={index} className={classes.yLabel} style={{ height: `${label.height}%` }}>{label.label}</p>
          )
        }
      </div>
      {
        graphData.length ? (
          graphData.map((data: any, index: number) =>
            <span
              id="data-bar"
              key={`data-bar-${index}`}
              onMouseEnter={handleMouseOver}
              onMouseLeave={handleMouseLeave}
              style={
                {
                  background: `var(--accent-light-blue)`,
                  border: '1px solid #fff',
                  width: data.width,
                  height: `${data.soc}%`,
                  position: 'relative'
                }
              }
            >
                <span
                  style={{
                    position: 'absolute',
                    color: '#fff',
                    width: data.width,
                    fontSize: '8px',
                    margin: '0 0 0 -2em'
                  }}
                >{data.soc}</span>
              </span>
          )
        ) : (
          null
        )
      }
    </div>
  );
};

export default SocGraph;
