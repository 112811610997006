import axiosCustom from './axios';

export type Optimization = {
    name: string
    operatingLimit: number
    enabled: boolean
    groupId: string
    schedule: string
    startDate: string |  null
    endDate: string | null
    durationInHours: number
}

export const getAllOptimizations = async (): Promise<Optimization[]> => {
    const url = '/optimizations';
    try {
        const data = await axiosCustom.get(url)
        return data.data.data;
    } catch(e) {
        console.error("Error in getAllOptimizations:: ", e);
        throw e;
    }
};
