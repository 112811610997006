import React from 'react';
import { useAppContext } from '../../context';
import { processPowerValues } from './data';
import { useStyles } from './styles';

interface DataDisplayProps {
  chargingSession: any;
  meterValues: any;
}

const GeneralInfo = ({ chargingSession }: any) => {
  const classes = useStyles();
  const { evses } = useAppContext();

  const { evseId, startTime, connectorNumber } = chargingSession;
  const evse = evses.filter((evse: any) => evse.id === evseId);
  const localDate = new Date(startTime).toLocaleString().split(',').reverse();
  const formattedDate = `${localDate[0]}, ${localDate[1]}`;

  const generalData = {
    "Session Date / Time": formattedDate,
    "EVSE": evse.length ? evse[0].vendor + ' ' + evse[0].serialNumber : '',
    "Connector Number": connectorNumber,
  };
  return (
    <div className={classes.dataCard}>
      <div className={classes.cardTitle}>General Information</div>
      {
        Object.entries(generalData).map((data: any, index: number) =>
          <div key={index + data} className={classes.dataBox}>
            <p className={classes.dataLabel}>{data[0]}</p>
            <p className={classes.dataValue}>{data[1]}</p>
          </div>
        )
      }
    </div>
  );
};

const EnergyInsights = ({ meterValues, chargingSession }: any) => {
  const classes = useStyles();
  const { duration } = chargingSession;
  const powerValues = processPowerValues(meterValues);
  const splitDuration = ((duration / 60) / 60).toFixed(2).split('.');
  const formattedDuration = `${splitDuration[0]} Hrs ${Math.round((Number(splitDuration[1]) * 60 / 100))} Mins`;
  const wattsToKw = (Number(chargingSession.totalPowerConsumption) / 1000).toFixed(2);
  const timeOptions: any = { hour: "2-digit", minute: "2-digit" };
  const extractTime = (idx: number) => new Date(powerValues[idx].timestamp).toLocaleTimeString("en-US", timeOptions);
  const generalData = {
    'Energy Used': `${wattsToKw} kWh`,
    'Time Frame': `${extractTime(0)} - ${extractTime(powerValues.length - 1)}`,
    'Duration': formattedDuration,
  };
  return (
    <div className={classes.dataCard}>
      <div className={classes.cardTitle}>Energy Insights</div>
      {
        Object.entries(generalData).map((data: any, index: number) =>
          <div key={index + data} className={classes.dataBox}>
            <p className={classes.dataLabel}>{data[0]}</p>
            <p className={classes.dataValue}>{data[1]}</p>
          </div>
        )
      }
    </div>
  );
};

const Batteryinsights = ({ chargingSession }: any) => {
    const classes = useStyles();

    const { duration, vehicle, startingSoc, endingSoc, optimizedLimit } = chargingSession;
    const splitDuration = ((duration / 60) / 60).toFixed(2).split('.');
    const formattedDuration = `${splitDuration[0]} Hrs ${Math.round((Number(splitDuration[1]) * 60 / 100))} Mins`;
    const generalData = {
      "Vehicle": vehicle,
      "Percent Charged": `${endingSoc - startingSoc}% charged over ${formattedDuration}`,
      "Charging Rate": optimizedLimit + 'kW'
    };
    return (
      <div className={classes.dataCard}>
        <div className={classes.cardTitle}>Battery Insights</div>
        {
          Object.entries(generalData).map((data: any, index: number) =>
            <div key={index + data} className={classes.dataBox}>
              <p className={classes.dataLabel}>{data[0]}</p>
              <p className={classes.dataValue}>{data[1]}</p>
            </div>
          )
        }
      </div>
    )
      ;
  }
;

const DataDisplay: React.FC<DataDisplayProps> = ({ chargingSession, meterValues }) => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <GeneralInfo chargingSession={chargingSession} />
      <EnergyInsights meterValues={meterValues} chargingSession={chargingSession} />
      <Batteryinsights chargingSession={chargingSession} />
    </div>
  );
};

export default DataDisplay;
