import React from 'react';
import Button from '@mui/material/Button';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

export default function NavBar() {
  const navigate = useNavigate();
  const { logout } = useAuth()
  
  return (
    <div style={{
      backgroundColor: 'var(--bg-dark-grey)',
      height: '100vh',
      minWidth: '140px',
      width: '140px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '20px 0',
      borderRight: '1px solid var(--black)',
      zIndex: '1'
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
      }}>
        <NavButton onClick={() => navigate('/')} startIcon={<HomeRoundedIcon/>} text="Home"/>
        <NavButton onClick={() => navigate('/details?tab=1&groupId=all')} startIcon={<AssignmentOutlinedIcon/>}
          text="Details"/>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
      }}>
        <NavButton onClick={() => {}} startIcon={<AccountCircleRoundedIcon/>} text="Account"/>
        <NavButton onClick={() => {
          logout()
          navigate('/login')
        }} startIcon={<LogoutRoundedIcon/>} text="Logout"/>
      </div>
    </div>
  );
}

function NavButton({ startIcon, onClick, text }: { startIcon: React.ReactElement, onClick: () => void, text: string }) {
  return (
    <Button
      onClick={onClick}
      color="secondary"
      style={{ textTransform: 'none', justifyContent: 'start', padding: '8px 24px' }}
      fullWidth
      startIcon={startIcon}>
      {text}
    </Button>
  );
}
