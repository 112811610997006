export const statusColor: Record<string, string> = {
  available: '#64D08F',
  preparing: '#FFD700',
  charging: '#00AEEF',
  suspendedevse: '#FFD700',
  suspendedev: '#FFD700',
  finishing:  '#FFD700',
  reserved: '#00AEEF',
  faulted: '#FF5630',
  unknown: '#B2B5BB',
  unavailable: '#FF5630',// '#B2B5BB',
  inactive: 'transparent',
};
