import React from 'react';
import { makeStyles } from '@mui/styles';
import ButtonNew from './ButtonNew';

interface NoDataCardProps {
  message: string;
  styles?: React.CSSProperties;
  buttonLabel?: string;
  buttonLabelIcon?: React.JSX.Element;
  onclick?: () => void;
  subtext?: string;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'var(--bg-light-grey)',
    borderRadius: '16px',
    fontSize: '20px',
    color: 'var(--bg-dark-grey)',
    margin: '24px',
    height: 'calc(100% - 48px)'
  }
});

const NoDataCard: React.FC<NoDataCardProps> = ({ message, styles, buttonLabel, onclick, subtext, buttonLabelIcon }) => {
  const classes = useStyles();
  return (
    <div className={classes.container} style={styles}>
      <h4>{message}</h4>
      {
        subtext ? (
          <p style={{marginBottom: '24px'}}>{subtext}</p>
        ) : null
      }
      {
        (buttonLabel && onclick) &&
        <ButtonNew
          label={buttonLabel}
          icon={buttonLabelIcon}
          handleClick={onclick}
          type='secondary'
        />
      }
    </div>
  )
};

export default NoDataCard;
