const timeInterval = 29 // minutes

export const evsesHealthCheck = (evses: any): any => {
  const healthOfEvses = {
    connected: 0,
    disconnected: 0,
    status: 'success'
  };
  const evseConnectivity = evses.reduce((acc: any, curr: any, index: number) => {
    const connectivity = curr.connected ? 'connected' : 'disconnected';
    if (connectivity === 'disconnected') {
      console.log(((Date.now() - new Date(curr.updated).getTime()) / 60000))
      acc['status'] = ((Date.now() - new Date(curr.updated).getTime()) / 60000) > timeInterval ? 'error' : 'warning';
    }
    acc[connectivity] = acc[connectivity] ? acc[connectivity] + 1 : 1;
    return acc;
  }, healthOfEvses);
  if (healthOfEvses.connected === 0) {
    healthOfEvses.status = 'error';
  }
    console.log('evseConnectivity:: ', evseConnectivity);
    return evseConnectivity;
};

export const connectorHealthCheck = (connectors: any) => {
  const connectorStatuses: any = {
    operational: 0,
    faulted: 0,
    unavailable: 0,
    status: 'success',

  };

  connectors.map((connector: any, index: number) => {
    const operationStatus =  connector.status === 'Unavailable' || connector.status === 'Faulted' ? connector.status : 'operational';
    connectorStatuses[operationStatus.toLowerCase()] += 1;
    
    const faultedStatus = connector.status === 'Faulted';
    const unavailableStatus = connector.status === 'Unavailable';
    const notUpdatedFor30Mins = ((Date.now() - new Date(connector.updated).getTime()) / 60000) > timeInterval;

    if (faultedStatus || (unavailableStatus && notUpdatedFor30Mins)) {
      connectorStatuses.status = 'error';
    }
    if (unavailableStatus && connectorStatuses.status !== 'error') {
      connectorStatuses.status = 'warning';
    }
  });

  if (!connectorStatuses.operational) {
    connectorStatuses.status = 'error';
  }

  return connectorStatuses;
};

export const activeSessionsHealthCheck = (chargingSessions: any): any => {
  const filteredActiveSessions = chargingSessions.filter((session: any) => session.isActive);
  const idleSessions = filteredActiveSessions.filter((cs: any) => cs.currentPower === 0);
  return {
    activeSessions: filteredActiveSessions.length,
    idleSessions: idleSessions.length,
    status: idleSessions.length ? 'warning' : 'success',
  };
};

export const systemHealthCheck: any = (evses: any, chargingSessions: any, connectors: any) => {
  const evsesHealth = evsesHealthCheck(evses);
  const connectorHealth = connectorHealthCheck(connectors);
  const activeSessionsHealth = activeSessionsHealthCheck(chargingSessions);
  const allHealthStatuses = [evsesHealth, connectorHealth, activeSessionsHealth].map((el: any) => el.status);
  const systemHealth =  allHealthStatuses.includes('error') ? 'error' : allHealthStatuses.includes('warning') ? 'warning' : 'success';

  return {
    evsesHealth,
    connectorHealth,
    activeSessionsHealth,
    systemHealth,
  }
};
