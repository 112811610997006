import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  summaryContainer: {
    minHeight: '158px',
    height: '158px',
    color: 'var(--bg-light-grey)',
    width: '100%',
    background: 'linear-gradient(var(--accent-light-blue), var(--panasonic-blue) 150%)',
    padding: '20px 40px',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  innerContainer: {
    padding: '20px 0'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  summaryHeader: {
    textAlign: 'left',
    fontWeight: '500',
    fontSize: '28px',
    margin: '0',
    padding: '10px 0',
  },
  cardContainer: {
    display: 'flex',
    alignContent: 'flex-start',
    gap: '15px'
  },
});
