import { CssBaseline, ThemeProvider } from '@mui/material';
import { ThemeProvider as ThemeProviderLegacy } from "@mui/styles";
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Group } from './api/groups';
import './App.css';
import { Footer } from './components/elements';
import NavBar from './components/nav-bar/NavBar';
import { useAppContext } from './context';
import { GroupContext } from './context/GroupContext';
import { useAuth } from './hooks/useAuth';
import { Login } from './pages';
import { customTheme } from './styles/theme';
import { routes } from './utils/routes';

const App: React.FC = () => {
  const fetchInterval = 1;
  const { getAllAppData, groups } = useAppContext();
  const { token, verifyToken } = useAuth();
  const [minutes, setMinutes] = useState<number>(fetchInterval);
  const [currentGroup, setCurrentGroup] = useState<Group | null>(null);
  const [allGroups, setAllGroups] = useState<Group[]>([]);

  const checkMinutes = () => {
    const currentMinutes = new Date().getMinutes();
    if (currentMinutes % fetchInterval === 0) {
      console.debug('Setting minutes:', currentMinutes);
      setMinutes(currentMinutes);
    }
  };

  useEffect(() => {
    if (groups.length > 0) {
      setAllGroups(groups);
    }
  }, [groups]);

  useEffect(() => {
    const minuteInterval = setInterval(checkMinutes, 60000);
    const updatedToken = verifyToken();

    if (updatedToken) {
      getAllAppData();
    }
    return () => {
      clearInterval(minuteInterval);
    };
  }, [token, minutes]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ThemeProvider theme={customTheme}>
      <ThemeProviderLegacy theme={customTheme}>
        <CssBaseline enableColorScheme />
        <div className="App">
          <GroupContext.Provider value={{ currentGroup, setCurrentGroup, allGroups, setAllGroups }}>
            <BrowserRouter>
              <Routes>
                {routes.map((page, index) => (
                  <Route
                    path={page.endpoint}
                    element={
                      <AuthRoute>
                        <>
                          <NavBar />
                          <div style={{ width: 'calc(100% - 140px)', zIndex: '0' }}>
                            <div style={{ width: '100%', height: 'calc(100% - 64px)' }}>
                              <page.component />
                            </div>
                            <Footer />
                          </div>
                        </>
                      </AuthRoute>
                    }
                    key={index}
                  />
                ))}
                <Route path="/login" element={<Login />} />
              </Routes>
            </BrowserRouter>
          </GroupContext.Provider>
        </div>
      </ThemeProviderLegacy>
    </ThemeProvider>
  );
};

function AuthRoute({ children }: { children: React.JSX.Element }): React.JSX.Element | null {
  const location = useLocation();
  const { token } = useAuth();

  if (!token) {
    return (
      <Navigate
        to={{ pathname: "/login" }}
        replace
        state={{ from: location }}
      />
    );
  }

  return children;
}

export default App;
