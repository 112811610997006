/* eslint @typescript-eslint/no-explicit-any: 0 */
import axios from 'axios';
import * as storage from '../utils/storage';
import { localStorageKey } from '../hooks/useAuth';

const pcms_rest_api_base = process.env.REACT_APP_LOCAL ? process.env.REACT_APP_PCMS_API_URL_BASE_LOCAL : `${process.env.REACT_APP_PCMS_API_URL_BASE}/${process.env.REACT_APP_STAGE}`;

const pcms_rest_api = axios.create({
  baseURL: pcms_rest_api_base,
});

pcms_rest_api.interceptors.request.use((config) => {
  const token = storage.getLocalStorage(localStorageKey);
  config.headers = config.headers ?? {};
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

pcms_rest_api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

const get = async (url: string, params?: Record<string, any>): Promise<any> => {
  console.info('GET: ', url);
  console.info("PARAMS:: ", params);
  try {
    return pcms_rest_api.get(url, params);
  } catch (e) {
    console.error(`Error Getting: ${url}:: `, e);
    throw e;
  }
};

const post = async (url: string, params: Record<string, any>): Promise<any> => {
  console.log("REST AP URL: ", pcms_rest_api_base);
  console.info('POST: ', url);
  console.dir(params, { depth: null });
  try {
    return pcms_rest_api.post(url, params);
  } catch (e) {
    console.error(`Error Posting: ${url}:: `, e);
    throw e;
  }
};

const patch = async (url: string, params: Record<string, any>): Promise<any> => {
  console.info('PATCH: ', url);
  console.dir(params, { depth: null })
  try {
    return pcms_rest_api.patch(url, params);
  } catch (e) {
    console.error(`Error Posting: ${url}:: `, e);
    throw e;
  }
};

const remove = async (url: string): Promise<any> => {
  console.info('REMOVE: ', url);
  try {
    return pcms_rest_api.delete(url);
  } catch (e) {
    console.error(`Error remove: ${url}:: `, e);
    throw e;
  }
};

const api = {
  get,
  post,
  patch,
  remove,
};

export default api;
