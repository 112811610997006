import React, { FormEvent, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useAuth } from "../../hooks/useAuth";
import { useStyles } from './styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { SubmitButton } from '../../components/elements/ButtonNew';
import logo from '../../assets/images/panasonic-white.svg'

export default function Login() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { login, resetPassword } = useAuth()
  const from = `${location.state?.from?.pathname ?? '/'}${location.state?.from?.search ?? ''}`

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false)
  const [resetPasswordSession, setResetPasswordSession] = useState<string | null>(null)
  const [resetPasswordUsername, setResetPasswordUsername] = useState<string | null>(null)
  
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    try {
      setLoading(true);
      const form = e.currentTarget;
      const formData = new FormData(form);
      const formJson = Object.fromEntries(formData.entries())
      
      if (!resetPasswordSession) {
        const loginRes = await login(formJson);
        if (loginRes.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
          setResetPasswordSession(loginRes.Session)
          // @ts-expect-error
          setResetPasswordUsername(formData.get('username'))
        } else {
          navigate(from, { replace: true });
        }
      }
      if (resetPasswordSession) {
        await resetPassword({ ...formJson, username: resetPasswordUsername! }, resetPasswordSession)
        navigate(from, { replace: true });
      }
    } catch (e) {
      console.error('Error in login submit: ', e);
      setError('There was an error logging in. Please try again.')
    }
    setLoading(false);
  };

  return (
    <div className={classes.background}>
      <header style={{ margin: '24px 0' }}>
        <img alt="Panasonic Logo" style={{ width: '240px' }} src={logo}/>
      </header>
      <div className={classes.container}>
        {resetPasswordSession ? (
          <div className={classes.main}>
            <form className={classes.form} onSubmit={handleSubmit}>
              <h3 style={{ color: '#0d1a26' }}>Please choose a new password</h3>
              <input
                className={classes.input}
                name="password"
                type="password"
                placeholder="New Password"
                required
              />
              <SubmitButton
                label="Continue"
                loading={loading}
                type="primary"
              />
            </form>
          </div>
        ) : (
          <div className={classes.main}>
            <div className={classes.header}>
              <h3 style={{ margin: '0' }}>Welcome back!</h3>
            </div>
            <form className={classes.form} onSubmit={handleSubmit}>
              <input
                className={classes.input}
                name="username"
                type="text"
                placeholder="Username"
                required
              />
              <div className={classes.passwordInputBox}>
                <input
                  className={classes.input}
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  required
                />
                <button
                  className={classes.showPasswordButton}
                  onClick={() => setShowPassword(showPassword => !showPassword)}
                  type="button"
                >
                  {
                    showPassword ?
                      <VisibilityOffIcon/> :
                      <VisibilityIcon/>
                  }
                </button>
              </div>
              <SubmitButton
                label="Login"
                loading={loading}
                type="primary"
              />
            </form>
            {error && (
              <span className={classes.error}>
                {error}
              </span>
            )}
          </div>
        )}
        <div className={classes.footer}>
          <p style={{ margin: 0 }}>&copy; Panasonic Corporation of North America.</p>
          <p style={{ margin: 0 }}>All Rights Reserved</p>
        </div>
      </div>
    </div>
  );
};
