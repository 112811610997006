import React, {useState, useEffect} from 'react';
import { createUseStyles } from 'react-jss';


const useStyles = createUseStyles({
  main: {
    textAlign: 'left',
  },
  time: {
    margin: 0,
  }
});


const Clock: React.FC = () => {
  const [time, setTime] = useState<Date>();
  const [intervalInMs] = useState(1000);
  const classes = useStyles();

  const setClock = () => {
    const date = new Date();
    setTime(date);
  }

  useEffect(() => {
    const advanceClock = setInterval(setClock, intervalInMs);
    return () => {
      clearInterval(advanceClock);
    }
  }, [time]);

  return (
    <div className={classes.main}>
      <p className={classes.time}>Local: {new Date().toLocaleString()}</p>
      <p className={classes.time}>UTC: {new Date().toUTCString()}</p>
    </div>
  )
};

export default Clock;
