import React, { useContext } from 'react';
import { AppContext, GroupContext } from '../../context';
import { Clock } from '../elements';
import GroupSelect from '../group-select';
import DashboardCard from './DashboardCard';
import { useStyles } from './styles';

type DashboardCardData = {
  label: string;
  value: React.JSX.Element | string | null;
  valueMetric?: string;
};

type SummaryDataType = {
  title: string;
  subtitle?: string | undefined;
  metric?: string | undefined;
  timeframe?: string | undefined;
  data: DashboardCardData[];
  button?: React.JSX.Element | undefined;
}

interface SummaryHeaderProps {
  data: SummaryDataType[];
  header?: string;
}

export default function SummaryHeader({ data, header }: SummaryHeaderProps) {
  const classes = useStyles();
  const { myTenant } = useContext(AppContext);
  const { allGroups, currentGroup } = useContext(GroupContext);
  
  return (
    <>
      <div className={classes.summaryContainer}>
        <div className={classes.titleContainer}>
          {
            header ?
              <h2 className={classes.summaryHeader}>{header}</h2>
              : allGroups.length > 0 ?
                <div className={classes.summaryHeader}>
                  <div>{currentGroup?.name ?? myTenant?.name ?? 'All Groups'} Details</div>
                  <GroupSelect />
                </div>
                : <h2 className={classes.summaryHeader}>Group Details</h2>
          }
          <Clock/>
        </div>
        <div className={classes.cardContainer}>
          {
            data.map(({ title, subtitle, data, metric, timeframe, button }, i) =>
              <DashboardCard
                key={i}
                cardTitle={title}
                subtitle={subtitle}
                data={data}
                metric={metric}
                timeframe={timeframe}
                button={button}
              />
            )
          }
        </div>
      </div>
    </>
  );
};
