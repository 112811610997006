import React from 'react';
import InsightsIcon from '@mui/icons-material/Insights';
import dayjs from 'dayjs';
import { Link } from "react-router-dom";



const sortByDate = (a: any, b: any) => {
  if (new Date(a['Session Started']).getTime() > new Date(b['Session Ended']).getTime()) {
    return -1
  } else {
    return 1
  }
}

export const formatActiveSessionTableData = (chargingSessions: any, evses: any) => {
  const formattedSessions = chargingSessions.map((session:any) => {
    const evse = evses.find((evse: any) => session.evseId === evse.id);
    
    return  {
      'Transaction Id': session.transactionId,
      'EVSE': evse ? evse.vendor : ' - ',
      'Connector Number': `${session.connectorNumber || ''}`,
      'Vehicle': session.vehicle,
      'Starting SOC': session.startingSoc || ' - ',
      'Ending SOC': session.endingSoc || ' - ',
      'Id tag': session.idTag,
      'Session Started': dayjs(session.startTime).format('MMM DD, YYYY HH:mm'),
      'Session Ended': dayjs(session.endTime).format('MMM DD, YYYY HH:mm'),
      'Duration': ((session.duration / 60) / 60).toFixed(2) + ' Hrs',
      'Total Energy Consumed': session.totalPowerConsumption ? (session.totalPowerConsumption / 1000).toFixed(2) + ' kWh' : '-',
      'Max Capacity / Optimized limit (kW)': `${session.maxCapacity} / ${session.optimizedLimit ? session.optimizedLimit : ' - '}`,
      'Insights': session.startingSoc ? 
        <Link to={`/charging-session/${session.transactionId}`}>{<InsightsIcon sx={{color: 'var(--panasonic-blue)', textAlign: 'right', width: '100%', '&:hover': { color: 'var(--accent-light-blue)' }}} />}</Link> : <p style={{textAlign: 'center', width: '100%'}}> - </p>
    }
  });
  return formattedSessions.sort(sortByDate);
}
