import React from 'react';
import { makeStyles } from '@mui/styles';
import Loading from './Loading';

const buttonStyles = makeStyles({
  main: {
    fontSize: '16px',
    position: 'relative',
    borderRadius: '18px',
    margin: 0,
    padding: '9px 17px',
    cursor: 'pointer',
    '&:active': {
      opacity: 0.7
    },
    '&:hover': {
      opacity: 0.7
    },
    '&:disabled': {
      opacity: 0.7
    }
  },
  labelWithIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
});

const styles = {
  primary: {
    backgroundColor: '#0041c0',
    border: 'none',
    color: '#fff',
  },
  secondary: {
    backgroundColor: 'transparent',
    border: '1px solid #0041c0',
    color: '#0041c0',
  },
  blank: {
    backgroundColor: 'transparent',
    border: 'none',
    color: '#000',
  }
}

interface ButtonPropsNew {
  label: string;
  icon?: React.ReactNode;
  handleClick: (args: any) => void;
  type: 'primary' | 'secondary' | 'blank';
  disabled?: boolean;
  loading?: boolean;
}

const ButtonText = ({ label, icon }: Partial<ButtonPropsNew>) => {
  const classes = buttonStyles();
  
  return (
    <>
      {
        icon ? (
          <div className={classes.labelWithIcon}>
            {icon}
            <p style={{ margin: '0 0 0 6px' }}>{label}</p>
          </div>
        ) : label
      }
    </>
  )
}

export const SubmitButton = ({label, loading, type, icon}: Pick<ButtonPropsNew,'label'|'loading'|'type'|'icon'>) => {
  const classes = buttonStyles();
  
  return (
    <button
      className={classes.main}
      disabled={loading}
      type="submit"
      style={styles[type]}
    >
      {
        loading ? (
            <Loading color={styles[type].color} size="1em"/>
        ) : <ButtonText label={label} icon={icon}/>
      }
    </button>
  );
}

const ButtonNew = ({ label, handleClick, disabled, loading, type, icon }: ButtonPropsNew) => {
  const classes = buttonStyles();

  return (
    <button
      disabled={disabled}
      className={classes.main}
      onClick={handleClick}
      style={styles[type]}
    >
      {
        loading ? (
          <>
            <p style={{ visibility: 'hidden' }}>{label}</p>
            <div style={{ position: 'absolute', right: '45%' }}>
              <Loading color={styles[type].color} size={16}/>
            </div>
          </>
        ) : <ButtonText label={label} icon={icon}/>
      }
    </button>
  );
};

export default ButtonNew;
