import React from 'react';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles({
  main: {
  },
  spinner: {
    padding: 0,
    margin: 0,
    height: '10px'
  }
});

interface LoadingProps {
  color: string;
  size: string | number;
}

const Loading = ({color, size}: LoadingProps) => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <CircularProgress className={classes.spinner} size={size} sx={{color: color, height: '80%'}}/>
    </div>
  );
};

export default Loading;
