import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Loading } from '../../../components/elements';
import DashboardCard from '../../../components/summary-header/DashboardCard';
import { useAppContext } from '../../../context';
import { systemHealthCheck } from './data';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    '&:hover > div': {
      border: '1px solid var(--accent-light-blue)'
    }
  },
  container: {
    backgroundColor: 'var(--bg-light-grey)',
    padding: '20px 40px',
    margin: '24px',
    borderRadius: '16px',
    textAlign: 'left',
    marginTop: '70px'
    // height: 'calc(100% - 48px)'
  },
  statusHeader: {
    textAlign: 'center',
  },
  statusGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '16px',
  }
});

const SystemHealth = (): React.JSX.Element => {
  const classes = useStyles();
  const { evses, chargingSessions, connectors, loadingData } = useAppContext();
  const {
    evsesHealth,
    connectorHealth,
    activeSessionsHealth,
    systemHealth,
  } = systemHealthCheck(evses, chargingSessions, connectors);

  const data = [{
    title: "EVSE Health",
    tab: 3,
    data: [{
      label: "Status",
      value: <CheckCircleRoundedIcon sx={{ lineHeight: '24px' }} color={evsesHealth.status} />
    }, {
      label: "",
      value: `${evsesHealth.connected} of ${evses.length} connected`
    }]
  },
    {
      title: "Active Charging Sessions",
      tab: 1,
      data: [{
        label: "Status",
        value: <CheckCircleRoundedIcon sx={{ lineHeight: '24px' }} color={activeSessionsHealth.status} />
      },
        { label: "Active", value: activeSessionsHealth.activeSessions },
        { label: "Issues", value: activeSessionsHealth.idleSessions }],
    },
    {
      title: "Connector Health",
      tab: 4,
      data: [{
        label: "Status",
        value: <CheckCircleRoundedIcon sx={{ lineHeight: '24px' }} color={connectorHealth.status} />
      }, {
        label: "",
        value: `${connectorHealth.operational} of ${connectors.length} operational`
      }]
    }];

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.statusHeader}>
          <CheckCircleRoundedIcon fontSize="large" color={loadingData ? 'success' : systemHealth} />
          <h3>Overall System Health</h3>
        </div>
        {loadingData ? <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loading color="#00AEEF" size="4rem" />
          </div> :
          <div className={classes.statusGrid}>
            {
              data.map(({ title, data, tab }, i) =>
                <Link
                  to={`/details?tab=${tab}&groupdId=all`}
                  style={{ textDecoration: "none" }}
                  key={`summary-card-${i}`}
                >
                  <DashboardCard
                    cardTitle={title}
                    data={data}
                  />
                </Link>
              )
            }
          </div>}
      </div>
    </div>
  );
};

export default SystemHealth;
