import React from 'react';
import { useAppContext } from '../../context';
import { StandardTable } from '../../components/elements';

const formatData = (chargingSessions: any, evses: any, groups: any) => {
  return chargingSessions.filter((session: any) => session.isActive).map((activeSession: any) => {
    const group = groups.find((group: any) => group.id === activeSession.groupId);
    return {
      "Started": activeSession.startTime,
      "Evse": evses.find((evse: any) => evse.id === activeSession.evseId).vendor || '',
      "Connector": activeSession.connectorNumber,
      "Vehicle": activeSession.vehicle,
      "Current Power": activeSession.currentPower,
      "Duration": ((activeSession.duration / 60) / 60).toFixed(2) + ' hrs',
      "Group": group ? group.name : 'None'
    }
  })
};

const ActiveSessionsList = (): React.JSX.Element => {
  const { evses, chargingSessions, groups, loadingData } = useAppContext();

  return (
    <StandardTable
      tableTop="Active Sessions"
      data={formatData(chargingSessions, evses, groups)}
      loading={loadingData}
      error={false}
    />
  );
};

export default ActiveSessionsList;
