import StopIcon from '@mui/icons-material/StopCircleOutlined';
import PlayIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import StatusIcon from '@mui/icons-material/SyncProblem';
import React from 'react';
import MoreIconList from '../../../components/ellipsis-menu/MoreIconList';
import { statusColor } from "../../../styles/statusColors";
import {
  requestRemoteStart,
  requestRemoteStop,
  requestStatusNotification
} from '../../../utils/ocppCommands';

const getMoreIcon = (connector: any, session?: any) => {
  const remoteStartStatuses = ['preparing'];
  const connectorMenu = [
    {
      icon: () => <StatusIcon color="info" />,
      title: 'Check Status',
      clickHandler: () => requestStatusNotification({
        connectorId: connector.connectorNumber,
        chargePointId: connector.chargepointId
      }),
      confirmText: 'Request status notification?'
    },
  ];

  if (session) {
    connectorMenu.unshift({
      icon: () => <StopIcon color="error" />,
      title: 'Stop Session',
      clickHandler: () => requestRemoteStop(connector.chargepointId, session.transactionId),
      confirmText: 'Stop the current charging session?'
    });
  }

  if (remoteStartStatuses.some((status) => status === connector.status.toLowerCase())) {
    connectorMenu.unshift({
      icon: () => <PlayIcon color="success" />,
      title: 'Start Session',
      clickHandler: async () => {},
      confirmText: 'Start a new charging session?'
    },)
  }

  return <MoreIconList connector={connector} menuItems={connectorMenu} />;
};


const formatConnector = (connectors: any[], evses: any, groups: any, sessions: any[]) => {
  return connectors.map(((connector: any) => {
    const evse = evses.find((evse: any) => evse.id === connector.chargepointId) || null;
    const activeSession = sessions.find(s => s.connectorId === connector.id && s.isActive);
    const {
      connectorNumber,
      maxCapacity,
      status,
      groupId,
    } = connector;
    return {
      'Number': connectorNumber,
      'Status': <p style={{ color: statusColor[status.toLowerCase()] }}>{status}</p>,
      'Group': groups.length ? groups.find((group: any) => group.id === groupId)?.name : groupId,
      'EVSE Vendor': evse ? evse.vendor : ' - ',
      'EVSE Model': evse ? evse.model : ' - ',
      'Max power': maxCapacity ? maxCapacity + ' kW' : '-',
      '': getMoreIcon(connector, activeSession)
    };
  }));
};

export {
  formatConnector,
};
