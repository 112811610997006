import React, { useContext } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { GroupContext } from '../../context/GroupContext';
import FormControl from '@mui/material/FormControl';

export default function GroupSelect() {
  const { currentGroup, setCurrentGroup, allGroups } = useContext(GroupContext);
  
  const handleChange = (event: SelectChangeEvent) => {
    const matchingGroup = allGroups.find(g => g.id === event.target.value);
    setCurrentGroup(matchingGroup || null);
  };
  
  return (
    <FormControl variant="standard">
      <Select
        id="group-select"
        value={currentGroup?.id ?? 'all'}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'Groups' }}
        displayEmpty
        sx={{
          '.MuiSvgIcon-root': {
            fill: '#f1f3fc',
          }
        }}
      >
        <MenuItem value="all">All Groups</MenuItem>
        {allGroups.map((group, index) =>
          <MenuItem key={`group-select-${index}`} selected={currentGroup?.id === group.id} value={group.id}>
            {group.name}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
