import axiosCustom from './axios';

export type MyTenant = {
    id: string;
    name: string;
    address: string;
    websocketReference: string;
}

export const getMyTenant = async (): Promise<any> => {
    const url = `/tenants/tenant`;
    try {
        const data = await axiosCustom.get(url);
        return data.data.data;
    } catch(e) {
        console.error("Error in getGroupsById:: ", e);
        throw e;
    }
};
