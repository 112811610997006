import React from 'react';
import { StandardTable } from '../../../components/elements';
import { useAppContext } from '../../../context';
import { formatOptimizations } from './data';
import { Optimization } from '../../../api/optimizations';

const OptimizationsTable = ({ optimizations }: { optimizations: Optimization[] }) => {
  const { groups, error, loadingData } = useAppContext();
  
  return (
    <StandardTable
      data={formatOptimizations(optimizations, groups)}
      tableTop="Optimizations"
      loading={loadingData}
      error={error}
    />
  );
};

export default OptimizationsTable;
