import React from 'react';
// import { useAppContext } from '../../context';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    color: 'red',
    minWidth: '50px',
    width: '80%',
    padding: '40px',
    margin: '20px auto',
  }
});

const ErrorCard = ({errorMessage, style}: {errorMessage?: string, style?: React.CSSProperties}) => {
  const classes = useStyles();
  // const { errorState } = useAppContext();

  const text = errorMessage || 'An error occured, please refresh the page';
  const newText = text?.split('\n').map((str, i)=> <p key={i}>{str}</p>);
  return (
    <div className={classes.container} style={style}>
      {newText}
    </div>
  )
};

export default ErrorCard;
