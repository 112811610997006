import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  main: {
    backgroundColor: 'transparent',
    borderRadius: '90px',
    width: '38px',
    height: '38px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    cursor: 'pointer',
    '&:active': {
      backgroundColor: '#1a1a1a1a',
    },
    marginLeft: 'auto',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  menuItemTitle: {
    marginLeft: '8px',
    color: 'var(--bg-dark-grey)',
    fontSize: '14px'
  }
});
