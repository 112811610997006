import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  background: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    height: '100%',
    width: '100%',
    background: 'linear-gradient(160deg, var(--accent-light-blue) 60%, #fff 60%)',
    zIndex: 0,
    '&::after': {
      content: "''",
      position: 'absolute',
      zIndex: -1,
      inset: 0,
      clipPath: 'polygon(100% 25%, 100% 50%, 10% 100%, 0% 100%, 0 80%)',
      background: 'linear-gradient(60deg, var(--accent-light-blue) 10%, var(--accent-light-green))'
    },
    '&::before': {
      content: "''",
      position: 'absolute',
      zIndex: -1,
      inset: 0,
      clipPath: 'polygon(0 0, 30% 0, 100% 50%, 10% 100%, 0 100%)',
      background: 'linear-gradient(20deg, var(--accent-light-blue) 10%, var(--panasonic-blue) 50%)'
    },
  },
  main: {
    padding: '40px',
  },
  container: {
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '420px',
    width: '480px',
    boxShadow: '0 10px 20px 0 rgba(60,66,87, 0.8)',
    borderRadius: '10px',
    position: 'relative'
  },
  header: {
    color: '#0d1a26',
    padding: '16px',
    fontSize: '25px',
    fontWeight: '500',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    padding: '32px',
  },
  input: {
    width: '100%',
    padding: '8px 16px',
    fontSize: '16px',
    border: '1px solid #636363',
    borderRadius: '18px',
    '&[type="submit"]': {
      border: 'none',
      backgroundColor: '#0041c0',
      color: '#FFF',
      fontWeight: 'bold'
    }
  },
  footer: {
    fontSize: '12px',
    backgroundColor: '#F4F4F4',
    padding: '8px',
    color: '#636363',
    borderRadius: '0 0 10px 10px',
  },
  error: { color: 'var(--error)', position: 'absolute', left: 0, width: '100%' },
  showPasswordButton: {
    backgroundColor: 'transparent',
    border: 'none',
    position: 'absolute',
    right: '0',
    top: '5px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  passwordInputBox: {
    margin: 0,
    padding: 0,
    width: '100%',
    display: 'flex',
    position: 'relative'
  }
});
