import React from 'react';
import { StandardTable } from '../../../components/elements';
import { useAppContext } from '../../../context';
import { formatEvse } from './data';

interface EvsesProps {
  evses: any[];
}

const Evses = ({ evses }: EvsesProps) => {
  const { error, loadingData, groups } = useAppContext();
  
  return (
    <StandardTable
      data={evses.length ? formatEvse(evses, groups) : []}
      tableTop="EVSEs"
      noDataMessage="No Evses Found"
      loading={loadingData}
      error={error}
    />
  );
};

export default Evses;
