import axiosCustom from './axios';

const getMeterValuesByTransactionId = async (qsParams?: any) => {
  const url = '/meter-values';
  if (!qsParams.transactionId) {
    throw Error("Missing transaction Id")
  }
  // const url = `/meter-values?transactionId=${qsParams.transactionId}&limit=200`;
  try {
    const data = await axiosCustom.get(url, {params: qsParams})
    return data.data.data;
  } catch(e) {
    console.error("Error in getMeterValues:: ", e);
    throw e;
  }
};

export {
  getMeterValuesByTransactionId,
};