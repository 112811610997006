import React from 'react';
import { makeStyles } from '@mui/styles';
import PanasonicBlue from '../../assets/images/panasonic-blue.svg';

const useStyles = makeStyles({
  container: {
    borderTop: '1px solid var(--border-light)',
    boxShadow: '0 0 10px 0px var(--border-light)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '64px',
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#fff'
  },
  logo: {
    height: '24px',
    marginLeft: '-140px'
  },
});

const Footer = () => {
  const classes = useStyles();
  
  return (
    <div className={classes.container}>
      <img alt="Panasonic Logo" className={classes.logo} src={PanasonicBlue}></img>
    </div>
  )
};

export default Footer;
